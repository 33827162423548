import { ToastModel } from 'hooks';
import {
  IconCheckCircle,
  IconClose,
  IconCloseCircle,
  IconInformation,
  IconInformationOutline,
} from 'components/icons';
import { isEqual } from 'lodash';

interface Props {
  toast?: ToastModel;
  removeToast?: (id?: string) => void;
}

const Toast = ({ toast, removeToast }: Props) => {
  const isTypeError = isEqual(toast?.type, 'error');
  const isTypeWarning = isEqual(toast?.type, 'warning');
  const isTypeInformation = isEqual(toast?.type, 'information');

  return (
    <div className='flex overflow-hidden rounded-[4px] drop-shadow-2'>
      <div
        className={`min-w-[4px] ${
          isTypeError
            ? 'bg-error'
            : isTypeWarning
            ? 'bg-warning'
            : isTypeInformation
            ? 'bg-information'
            : 'bg-success'
        }`}
      />
      <div
        className={`group flex max-w-[394px] items-center space-x-[12px] px-[12px] py-[16px] ${
          isTypeError
            ? 'bg-error-light'
            : isTypeWarning
            ? 'bg-warning-light'
            : isTypeInformation
            ? 'bg-information-light'
            : 'bg-success-light'
        }`}
      >
        {isTypeError ? (
          <IconCloseCircle className='min-h-[24px] min-w-[24px] text-error' />
        ) : isTypeWarning ? (
          <IconInformationOutline className='min-h-[24px] min-w-[24px] text-warning' />
        ) : isTypeInformation ? (
          <IconInformation className='min-h-[24px] min-w-[24px] text-information' />
        ) : (
          <IconCheckCircle className='min-h-[24px] min-w-[24px] text-success' />
        )}
        <div className='flex flex-col space-y-[4px]'>
          <span className='font-[600] text-[#222222] line-clamp-[1]'>
            {isTypeError
              ? 'Lỗi'
              : isTypeWarning
              ? 'Cảnh báo'
              : isTypeInformation
              ? 'Thông tin'
              : 'Thành công'}
          </span>
          <span className='text-[#474747] line-clamp-[3]'>{toast?.content}</span>
        </div>
        <div className='min-h-[24px] min-w-[24px]'>
          <button
            type='button'
            className='hidden group-hover:block'
            onClick={() => removeToast?.(toast?.id)}
          >
            <IconClose className='min-h-[24px] min-w-[24px] text-placeholder ' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
